import styles from './Navbar.module.scss';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import Auth from '../auth/Auth';
import AuthNot from '../auth/AuthNot';
import PfpMedium from '../user/PfpMedium';
import ProfileMenu from '../menus/ProfileMenu';
import { useState } from 'react';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    return (
        <>
            <nav className={styles.navbar}>
                <div className={styles.content}>
                    <a href="/">
                        <img className={styles.logo} src="/assets/images/logos/logo.png" alt="Studian" />
                    </a>
                    <Auth>
                        <div className={styles.profile} onClick={ toggleMenu }>
                            <PfpMedium />
                        </div>
                    </Auth>
                    <AuthNot>
                        <div className={styles.links}>
                            <SecondaryButton onClick={() => { window.location.href = '/login'}}>Logga in</SecondaryButton>
                            <PrimaryButton onClick={() => { window.location.href = '/signup'}}>Skapa konto</PrimaryButton>
                        </div>
                    </AuthNot>
                </div>
            </nav>
            <Auth>
                <ProfileMenu open={menuOpen} />
            </Auth>
        </>
    );
}

export default Navbar;