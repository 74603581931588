import styles from './CreateSetForm.module.scss';
import NormalInput from '../../inputs/NormalInput';
import TermCardWrapper from './sets/TermCardWrapper';
import { useState } from 'react';

function CreateSetForm({ onChange, defaultName, defaultSubject, defaultTerms }) {
    const [setName, setSetName] = useState(defaultName || '');
    const [setSubject, setSetSubject] = useState(defaultSubject || '');
    const [terms, setTerms] = useState(defaultTerms || []);

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setSetName(newName);
        onChange(newName, setSubject, terms);
    };

    const handleSubjectChange = (e) => {
        const newSubject = e.target.value;
        setSetSubject(newSubject);
        onChange(setName, newSubject, terms);
    };

    const handleTermsChange = (newTerms) => {
        setTerms(newTerms);
        onChange(setName, setSubject, newTerms);
    };

    return (
        <div className={styles.form}>
            <div className={styles.inputWrapper}>
                <NormalInput
                    label="Namn"
                    title="Skriv namnet på läxan"
                    placeholder="Skriv namnet på läxan"
                    type="text"
                    onChange={handleNameChange}
                    value={setName}
                />
                <NormalInput
                    label="Ämne"
                    title="Skriv ämne"
                    placeholder="Skriv ämnet"
                    type="text"
                    onChange={handleSubjectChange}
                    value={setSubject}
                />
            </div>
            <div className={styles.termWrapper}>
                <h3>Termer</h3>
                <TermCardWrapper onChange={handleTermsChange} defaultTerms={defaultTerms} />
            </div>
        </div>
    );
}

export default CreateSetForm;