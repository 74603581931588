import styles from './SetMethodsNav.module.scss';
import { Copy, EditPencil, OneFingerSelectHandGesture, PlaystationGamepad } from 'iconoir-react';

function SetMethodsNav({ setId, selected }) {
    return (
        <div className={styles.nav}>
            <a href={`/set/${setId}/flashcards`} className={`${styles.item} ${selected === 'flashcards' ? styles.selected : ''}`}>
                <Copy />
                <span className={styles.label}>Flashcards</span>
            </a>
            <a href={`/set/${setId}/write`} className={`${styles.item} ${selected === 'write' ? styles.selected : ''}`}>
                <EditPencil />
                <span className={styles.label}>Skriv</span>
            </a>
            <a href={`/set/${setId}/flashcards`} className={`${styles.item} ${selected === 'match' ? styles.selected : ''}`}>
                <OneFingerSelectHandGesture />
                <span className={styles.label}>Matcha</span>
            </a>
            <a href={`/set/${setId}/games`} className={`${styles.item} ${selected === 'games' ? styles.selected : ''}`}>
                <PlaystationGamepad />
                <span className={styles.label}>Spel</span>
            </a>
        </div>
    );
}

export default SetMethodsNav;