import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import styles from './Menu.module.scss'; // Create a shared style file for consistent menu styles

const contentStyle = {
    borderRadius: '.5rem',
    padding: '0',
    width: 'min(90%, 200px)',
    boxShadow: 'none'
};

const arrowStyle = {
    color: '#fff'
};

function Menu({ triggerElement, items, className }) {
    return (
        <Popup
            trigger={triggerElement}
            position="bottom center"
            {...{ contentStyle, arrowStyle }}
        >
            {close => (
                <div className={styles.content}>
                    {items.map((item, index) => (
                        <span key={index} className={styles.item} onClick={() => { item.onClick(close) }}>
                            {item.icon}
                            {item.label}
                        </span>
                    ))}
                </div>
            )}
        </Popup>
    );
}

export default Menu;