import SetCardWrapperLg from "../../../sets/cards/SetCardWrapperLg";
import PublicSetCard from "../../../sets/cards/PublicSetCard";
import SearchInput from "../../../inputs/SearchInput";
import { useState, useEffect } from 'react';
import axios from 'axios';
import LazyLoad from "react-lazy-load";
import styles from "../Dashboard.module.scss";
import { Plus } from "iconoir-react";

function SetScreen() {
    const [ setIds, setSetIds ] = useState([]);
    
    function retrieveSets() {
        axios.get(`https://api.theodorlundin.se/studian/set/get/by-creator?active=true`, {
            withCredentials: true
        }).then(response => {
            setSetIds(response.data.sets);
        }).catch(error => {
            console.error('Error with API call:', error.message);
        });
    }

    useEffect(() => {
        retrieveSets();
    }, []);

    return (
        <>  
            { setIds.length > 0 ? 
                (
                    <>
                        <div style={{ marginBottom: '2rem' }}>
                            <SearchInput placeholder="Sök efter läxa" />
                        </div>
                        <SetCardWrapperLg>
                            { setIds.map((set_id) => <PublicSetCard key={set_id} setId={set_id} manage={true} onDelete={retrieveSets} />) }
                        </SetCardWrapperLg>
                    </>
                ) : (
                    <div className={styles.noContent}>
                        <LazyLoad className={styles.lazyLoad}>
                            <img src="/assets/images/graphics/illustrations/shrug.png" alt="" />
                        </LazyLoad>
                        <h1>Skapa din första läxa!</h1>
                    </div>
                )
            }

            <a href="/create/set" className={styles.newButton}>
                <Plus />
            </a>
        </>
    );
}

export default SetScreen;