import styles from './MainFooter.module.scss';
import ClickableDropdown from '../dropdowns/ClickableDropdown';

function MainFooter() {
    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                <div className={styles.branding}>
                    <a href='/'>
                        <img src='/assets/images/logos/logo.png' alt='Studian' className={styles.logo} />
                    </a>
                    <strong>© Studian 2024</strong>
                    <a href='mailto:support@studian.se'>support@studian.se</a>
                </div>
                <div className={styles.links}>
                    <ClickableDropdown title='Studian' fill='true'>
                        <a href='/integrity'>Integritetspolicy</a>
                        <a href='/conditions'>Villkor och riktlinjer</a>
                        <a href='/'>Cookies</a>
                        <a href='mailto:support@studian.se'>support@studian.se</a>
                    </ClickableDropdown>
                    <ClickableDropdown title='För lärare' fill='true'>
                        <a href='/create/set'>Skapa läxa</a>
                        <a href='/new/test'>Skapa läxförhör</a>
                        <a href='/chat'>Skicka meddelande</a>
                    </ClickableDropdown>
                    <ClickableDropdown title='För elever' fill='true'>
                        <a href='/test/join'>Gå med i läxförhör</a>
                        <a href='/explore'>Utforska läxor</a>
                        <a href='/chat'>Skicka meddelande</a>
                    </ClickableDropdown>
                    <ClickableDropdown title='Konto' fill='true'>
                        <a href='/signup'>Skapa konto</a>
                        <a href='/login'>Logga in</a>
                    </ClickableDropdown>
                </div>
            </div>
        </footer>
    );
}

export default MainFooter;