import styles from './CreateSet.module.scss';
import Navbar from "../../components/navbars/Navbar";
import Auth from "../../components/auth/Auth";
import ContainerSm from '../../containers/ContainerSm';
import Section from '../../containers/Section';
import CreateSetForm from "../../components/pages/create/CreateSetForm";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ErrorMessage from "../../components/popups/message/ErrorMessage";
import { useState } from 'react';
import axios from 'axios';

function CreateSet() {
    const [ setName, setSetName ] = useState('');
    const [ setSubject, setSetSubject ] = useState('');
    const [ terms, setTerms ] = useState([]);
    const [ error, setError ] = useState('');

    function handleUpdate(nameValue, subjectValue, termValues) {
        setSetName(nameValue);
        setSetSubject(subjectValue);
        setTerms(termValues);
    }

    function handleSubmit() {
        // Format a set object
        const termsArray = terms.map(term => {
            return [term.term, term.definition];
        });

        // Call the API and create the set
        axios.post('https://api.theodorlundin.se/studian/set/manage/create', {
            name: setName,
            subject: setSubject,
            content: termsArray
        }, {
            withCredentials: true
        }).then(() => {
            window.location.href = '/dashboard';
        }).catch(error => {
            const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
            setError(message);
        });
    }

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <ContainerSm>
                    <Section>
                        <div className={styles.header}>
                            <h2>Skapa läxa</h2>
                            <PrimaryButton onClick={handleSubmit}>Skapa</PrimaryButton>
                        </div>
                        { error ? <ErrorMessage message={error} /> : null }
                        <CreateSetForm onChange={handleUpdate} />
                    </Section>
                </ContainerSm>
            </Auth>
        </div>
    );
}

export default CreateSet;