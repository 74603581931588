import { useEffect, useState } from 'react';
import styles from './Write.module.scss';
import {  } from 'iconoir-react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import UserLink from '../../components/user/UserLink';
import SetMethodsNav from '../../components/pages/set/SetMethodsNav';
import PrimaryButton from '../../components/buttons/PrimaryButton';

function Write({ setId, setData }) {
    const [ termsLeft, setTermsLeft ] = useState(null);
    const [ incorrectTerms, setIncorrectTerms ] = useState([]);
    const [ progress, setProgress ] = useState(null);
    const [ index, setIndex ] = useState(0);
    const [ input, setInput ] = useState('');

    useEffect(() => {
        if (!setData) return;
        const mappedTerms = setData.content.map(pair => {
            return { term: pair[0], definition: pair[1] };
        });
        
        setTermsLeft(shuffleArray(mappedTerms));
        setProgress(new Array(mappedTerms.length).fill(null));
    }, [setData]);

    // Shuffle terms
    function shuffleArray(array) {
        let returnArray = [...array];
        let currentIndex = returnArray.length;

        while (currentIndex !== 0) {
            const randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [returnArray[currentIndex], returnArray[randomIndex]] = [returnArray[randomIndex], returnArray[currentIndex]];
        }

        return returnArray;
    }

    // Answer
    function handleAnswer() {
        console.log(index);
        
        const isCorrect = input === termsLeft[index].term;
        
        // Update progress at the current index
        const newProgress = [...progress];
        newProgress[index] = isCorrect;
        setProgress(newProgress);
    
        if (!isCorrect) {
            setIncorrectTerms([...incorrectTerms, termsLeft[index]]);
        }
    
        setInput('');
    
        // Move to the next term
        if (index === termsLeft.length - 1) {
            if (incorrectTerms.length > 0) {
                setIndex(0);
                setTermsLeft(shuffleArray(incorrectTerms));
                setProgress(new Array(incorrectTerms.length).fill(null));
                setIncorrectTerms([]);
            } else {
                handleDone();
            }
        } else {
            setIndex(index + 1);
        }
    }

    useEffect(() => {
        console.log(progress);
    }, [ progress ]);

    useEffect(() => {
        console.log(incorrectTerms);
    }, [ incorrectTerms ]);

    // Done
    function handleDone() {

    }

    // Keyboard
    function handleKeyDown(event) {
        if (event.key === 'Enter' || event.code === 'Enter') {
            event.preventDefault();
            handleAnswer();
        }
    }

    return (
        <div className={styles.write}>
            <div className={styles.main}>
                <SetMethodsNav setId={setId} selected='write' />
                <div className={styles.container} onKeyDown={handleKeyDown}>
                    <h2>Skrivtest</h2>
                    <div className={styles.progress}>
                        {termsLeft?.map((term, index) => {
                            let progressClass = '';
                            if (progress[index] !== null) {
                                progressClass = progress[index] ? styles.correct : styles.incorrect;
                            }
                            return (
                                <div className={`${styles.progressDiv} ${progressClass}`} key={index}></div>
                            );
                        })}
                    </div>
                    <span className={styles.definition}>
                        { termsLeft 
                            ? termsLeft[index].definition
                            : <Skeleton width={50} />
                        }
                    </span>
                    <input 
                        type="text" 
                        className={styles.input} 
                        value={input} 
                        onChange={(event) => setInput(event.target.value)} 
                        onKeyDown={handleKeyDown}
                        autoFocus 
                    />
                    <div className={styles.buttonWrapper}>
                        <PrimaryButton onClick={() => { setInput(''); handleAnswer(); }}>Skippa</PrimaryButton>
                        <PrimaryButton onClick={handleAnswer}>Svara</PrimaryButton>
                    </div>
                </div>
                <div className={styles.ad}></div>
            </div>
            <div className={styles.creator}>
                { setData
                    ? <UserLink userId={setData.creator} />
                    : <Skeleton width={150} height={48} />
                }
            </div>
        </div>
    );
}

export default Write;